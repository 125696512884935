<template>
    <div class="container">
        <div class="device-header border-container">
            <div class="title">
                <div class="text">
                    <div class="text-1">设备序列号：{{detail.serial_number}}</div>
                </div>
                <div class="btn">
                    <device-diagnose btnType='primary' :item="detail" style="margin-right: 10px;" btnText='设备诊断'/>
                    <el-button type="default" class="operation color" @click="openEditVisible"><i class="iconfont icon-edit"></i> 编辑</el-button>
                    <el-button type="default" class="operation color-red" @click="handleDelete"><i class="iconfont icon-delete"></i> 删除</el-button>
                    <!-- <el-button type="default" class="operation color" @click.stop="openVideo">预览</el-button> -->
                </div>
            </div>
            <div class="center">
                <div class="center-item">
                    <span class="key">通道名称：</span>
                    <span class="value">{{detail.name}}</span>
                </div>
                <div class="center-item">
                    <span class="key">通道号：</span>
                    <span class="value">{{detail.channel}}</span>
                </div>
            </div>
            <div class="center no-pb">
                <div class="center-item">
                    <span class="key">所属学校：</span>
                    <span class="value">{{detail.school_name || '-'}} <i class="iconfont icon-edit"
                            @click="openClassifyVisible" v-if="userType === USER_TYPE.ORG"/></span>
                </div>
                <div class="center-item" v-if="detail.type === 1">
                    <span class="key">所属班级：</span>
                    <span class="value">{{detail.class_name || '-'}}<i class="iconfont icon-edit"
                            @click="openClassVisible" /></span>
                </div>
            </div>
        </div>
        <div class="device-info border-container">
            <el-tabs v-model="activeName">
                <el-tab-pane label="设备开放时间" name="time">
                    <open-time :buttonType="primary" :btnText="btnText" @refresh="getDetail" :item="detail.config"
                        :id="detail.id" />
                    <div class="open-time">
                        <el-table :data="weeksList"
                            :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                            :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" class="margin">
                            <el-table-column prop="week" label="周" width="200px" />
                            <el-table-column prop="time" label="开放时间">
                                <div class="time" slot-scope="scope" v-if="scope.row.timeList.length > 0">
                                    <div class="time-item" v-for="(item, index) in scope.row.timeList" :key="index">
                                        {{item.time | timesFormat}}
                                    </div>
                                </div>
                                <div v-else-if="scope.row.type == 0">全天开放</div>
                                <div v-else>不开放</div>
                            </el-table-column>
                            <div class="" slot="empty">
                                <no-data></no-data>
                            </div>
                        </el-table>
                    </div>
                </el-tab-pane>
                <el-tab-pane label="设备开放范围" name="area">
                    <el-table :data="tableData"
                        :header-cell-style="{height: '40px', background: 'linear-gradient(180deg,rgba(251,252,253,1) 0%,rgba(246,248,250,1) 100%)'}"
                        :cell-style="{height: '50px',padding: '5px 0'}" header-align="center" class="margin">
                        <el-table-column prop="name" label="开放端" width="200px" />
                        <el-table-column label="操作">
                            <template slot-scope="scope">
                                <el-switch v-model="detail[scope.row.key]" @change="changeOperation(scope.row)"/>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
                <!-- <el-tab-pane label="设备云储存" name="storage">
                    <div class="center">
                        <div class="center-item" @click="cloudStorageEnableChange">
                            <span class="key">通道名称：</span>
                            <span class="value">{{detail.name}}</span>
                        </div>
                        <div class="center-item">
                            <span class="key">通道号：</span>
                            <span class="value">{{detail.channel}}</span>
                        </div>
                    </div>
                </el-tab-pane> -->
            </el-tabs>
        </div>
        <el-dialog title="编辑设备基本信息" width="504px" :visible.sync="editVisible">
            <div class="form-item">
                <div class="key">设备类型</div>
                <div class="value">
                    <el-radio v-model="dialogForm.type" :label="1">班级</el-radio>
                    <el-radio v-model="dialogForm.type" :label="2">公共区域</el-radio>
                </div>
            </div>
            <div class="form-item">
                <div class="key">通道名称</div>
                <el-input class="value" v-model="dialogForm.name" placeholder="请输入通道名称"></el-input>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="editVisible = false">取消</el-button>
                <el-button type="primary" @click="handleEdit">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="修改所属学校" width="504px" :visible.sync="classifyVisible">
            <div class="form-item">
                <div class="key">所属学校</div>
                <el-select class="value" v-model="dialogForm.school_id" placeholder="请选择所属学校">
                    <el-option v-for="item in schoolList" :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="classifyVisible = false">取消</el-button>
                <el-button type="primary" @click="handleBindSchool">确定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="关联班级" width="504px" :visible.sync="classVisible">
            <div class="form-item">
                <div class="key">关联班级</div>
                <el-select class="value" v-model="dialogForm.class_id" placeholder="请选择关联班级">
                    <el-option :label="item.name" :value="item.id" :key="item.id" v-for="item in classList" />
                </el-select>
            </div>
            <span slot="footer" class="dialog-footer" style="display: flex;justify-content: center">
                <el-button @click="classVisible = false">取消</el-button>
                <el-button type="primary" @click="handleClass">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Core from '@/core';
import axios from 'axios';
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export default {
    components: {
        OpenTime: () => import('./components/OpenTime'),
        NoData: () => import('@/components/Empty.vue'),
        DeviceDiagnose: () => import('./components/DeviceDiagnose'),

    },
    props: {},
    data() {
        return {
            userType: Core.Data.getUserType(),
            USER_TYPE: Core.Const.USER_TYPE,
            device_id: '',
            detail: {},
            tableData: [
                // {
                //     api_name: 'schoolOpenAll',
                //     key: 'flag_open_school',
                //     name: '学校总开放'
                // },
                {
                    api_name: 'parentOpen',
                    key: 'flag_open_parent',
                    name: '家长开放'
                },
                // {
                //     api_name: 'teacherOpen',
                //     key: 'flag_open_teacher',
                //     name: '教师开放'
                // },
                {
                    api_name: 'parentVoiceOpen',
                    key: 'flag_open_parent_voice',
                    name: '家长声音'
                },
                // {
                //     api_name: 'teacherVoiceOpen',
                //     key: 'flag_open_teacher_voice',
                //     name: '教师声音'
                // }
            ],
            classifyVisible: false,
            classVisible: false,
            editVisible: false,
            dialogForm: {
                school_id: '',
                class_id: '',
                name: '',
                type: '',
            },
            schoolList: [],
            classList: [],
            org_id: Core.Data.getBelong(),
            accessToken: '',
            activeName: 'time',
            primary: 'primary',
            weeksList: [],
            btnText: '设置开放时间'
        };
    },
    watch: {},
    computed: {},
    created() {
        this.device_id = this.$route.query.id;
        // Core.Api.Device.cloudStorageInfo(this.device_id).then((res) => {
        //     console.log('cloudStorageInfo', res)
        // });
    },
    mounted() {
        this.getDetail();
        this.getAccessToken();
        if (this.userType === this.USER_TYPE.ORG) {
            this.getSchoolAll();
        }
    },
    methods: {
        cloudStorageEnableChange() {
            Core.Api.Device.cloudStorageEnable(this.device_id, 1).then((res) => {
                console.log('cloudStorageEnable', res)
            });
        },
        getDetail() {
            Core.Api.Device.detail(this.device_id).then((res) => {
                console.log('getDetail res:', res);
                this.detail = res.detail;
                if (res.detail.config) {
                    this.weeksList = JSON.parse(res.detail.config);
                }
                for (const item of this.tableData) {
                    this.detail[item.key] = res.detail[item.key] ? true : false;
                }
                this.getClassAll();
            });
        },
        getSchoolAll() {
            Core.Api.OrgSystem.getSchoolAll(this.org_id.id).then((res) => {
                this.schoolList = res.list;
            });
        },
        getClassAll() {
            Core.Api.Device.classBySchool(this.detail.school_id).then((res) => {
                console.log('getClassAll res:', res);
                this.classList = res.list;
                for (const item of res.list) {
                    if (item.id === this.detail.class_id) {
                        this.detail.class_name = item.name;
                        break;
                    }
                }
            });
        },
        openEditVisible() {
            this.dialogForm.name = this.detail.name;
            this.dialogForm.type = this.detail.type;
            this.editVisible = true;
        },
        handleEdit() {
            Core.Api.Device.save(
                this.device_id,
                this.dialogForm.name,
                this.detail.serial_number,
                this.detail.school_id,
                this.detail.channel,
                this.detail.serial_number,
                this.dialogForm.type,
            ).then((res) => {
                this.$message.success('修改成功');
                this.editVisible = false;
                this.getDetail();
            });
        },
        openClassifyVisible() {
            this.dialogForm.school_id = this.detail.school_id;
            this.classifyVisible = true;
        },
        handleBindSchool() {
            Core.Api.Device.bindSchool(
                this.device_id,
                this.dialogForm.school_id
            ).then((res) => {
                this.$message.success('修改成功');
                this.classifyVisible = false;
                this.getDetail();
            });
        },
        openClassVisible() {
            this.classVisible = true;
            this.dialogForm.class_id = this.detail.class_id;
        },
        handleClass() {
            Core.Api.Device.bindClass(
                this.device_id,
                this.dialogForm.class_id
            ).then((res) => {
                this.$message.success('绑定成功');
                this.classVisible = false;
                this.getDetail();
            });
        },

        changeOperation(item) {
            let apiName = item.api_name;
            Core.Api.Device[apiName](this.device_id).then((res) => {
                this.$message.success('操作成功');
                this.getDetail();
            });
        },
        getAccessToken() {
            Core.Api.Device.accessToken().then((res) => {
                this.accessToken = res.access_token;
            });
        },
        openVideo() {
            const baseUrl = 'https://open.ys7.com/api/lapp/v2/live/address/get';
            const config = {
                accessToken: this.accessToken,
                deviceSerial: this.detail.serial_number,
                channelNo: this.detail.channel,
                expireTime: 86400,
                quality: 2,
                protocol: 3
            };
            axios({
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded'
                },
                method: 'post',
                url: baseUrl,
                params: config
            }).then((res) => {
                this.$router.push(
                    `/public-part/video-player?videoSrc=${res.data.data.url}`
                );
            });
        },
        handleDelete() {
            this.$confirm('确定执行删除操作?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                Core.Api.Device.delete(this.device_id).then((res) => {
                    this.$message.success('删除成功');
                    this.$router.go(-1);
                });
            });
        },
    }
};
</script>
<style lang="scss" scoped>
.container {
    .device-header {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 20px;
        margin: 20px;

        .title {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 14px;
            .text {
                display: flex;
                flex-direction: row;
                align-items: center;
                .text-1 {
                    font-size: 20px;
                    color: #323538;
                    font-weight: 500;
                    margin-right: 16px;
                }
                .status {
                    font-size: 14px;
                    color: #363d42;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    .color {
                        color: #13c176;
                        font-size: 36px;
                    }
                }
            }

            .btn {
                .operation {
                    height: 32px;
                    font-size: 14px;

                    &.color {
                        color: #1d517e;
                        border: 1px solid #1d517e;
                    }
                    &.color-red {
                        color: #ff3e05;
                        border: 1px solid #ff3e05;
                    }
                }
            }
        }
        .center {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-bottom: 20px;
            font-size: 14px;
            &.no-pb {
                padding-bottom: 0;
            }
            .center-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-right: 60px;
                width: 50%;
                .key {
                    color: #8090a6;
                    width: 80px;
                }
                .value {
                    color: #363d42;
                    width: calc(100% - 80px);
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    &.width {
                        width: 500px;
                    }
                    .iconfont {
                        margin-left: 8px;
                        cursor: pointer;
                        color: #1d517e;
                    }
                }
            }
        }
        .footer {
            .footer-1 {
                font-size: 14px;
                color: #999999;
            }
        }
    }
    .device-info {
        margin: 20px;
        padding: 10px 20px 20px 20px;
        background-color: #fff;
        .title {
            font-size: 16px;
            color: #3f4549;
            font-weight: 500;
        }
        .margin {
            margin-top: 20px;
        }
        .time {
            display: flex;
            flex-direction: row;
            align-items: center;
            .time-item {
                padding-right: 10px;
            }
        }
    }
    .form-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        + .form-item {
            margin-top: 16px;
        }
        .key {
            color: #8090a6;
            font-size: 14px;
            width: 120px;
            text-align: right;
            box-sizing: border-box;
            padding-right: 20px;
        }
        .value {
            width: calc(100% - 120px);
            box-sizing: border-box;
            padding-right: 20px;
        }
    }
}
</style>
<style lang="scss">
.course-info .my-tabs {
    > .el-tabs__header.is-top {
        .el-tabs__active-bar {
            width: 64px;
            height: 4px;
            border-radius: 2px;
        }
        #tab-special_column,
        #tab-student_list,
        #tab-data_analysis {
            height: 60px;
            line-height: 60px;
            font-size: 16px;
            font-weight: 500;
        }
    }
    > .el-tabs__content {
        padding: 16px 0 16px 20px;
    }
}
</style>
