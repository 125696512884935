import { render, staticRenderFns } from "./device-detail.vue?vue&type=template&id=79d2b7b7&scoped=true"
import script from "./device-detail.vue?vue&type=script&lang=js"
export * from "./device-detail.vue?vue&type=script&lang=js"
import style0 from "./device-detail.vue?vue&type=style&index=0&id=79d2b7b7&prod&lang=scss&scoped=true"
import style1 from "./device-detail.vue?vue&type=style&index=1&id=79d2b7b7&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79d2b7b7",
  null
  
)

export default component.exports